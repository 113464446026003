.articles__list {
	> li + li {
		border-top: 1px solid rgba(color(base), 0.1);
		margin-top: 20px;
		padding-top: 20px;
	}
}

.articles__list-hdr {
	font-size: 18px;
	margin-bottom: 5px;

	a {
		display: block;
	}
}

.articles__list-summary {
	font-size: 14px;
	margin-bottom: 10px;
}
