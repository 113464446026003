.container {
	overflow: hidden;
}

.contain {
	@include clearfix;
}

.screen-reader {
	clip: rect(0 0 0 0);
	display: block !important;
	position: absolute;
	width: auto !important;
}

.alt {
	@include alt;
}

.hide,
.js .js-hide {
	display: none;
}

.left {
	float: left;
}

.right {
	float: right;
}

.no-float {
	float: none;
}

.block {
	display: block;
}

.centered {
	margin: 0 auto;
	text-align: center;
}

.highlight {
	color: color(highlight);
}
