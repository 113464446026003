@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}

@function color($color) {
	@return map-get($colors, $color);
}

@function timing($timing) {
	@return map-get($timings, $timing);
}

@function font($font) {
	@return map-get($fonts, $font);
}

@function bp($bp) {
	@return map-get($breakpoints, $bp);
}

@mixin font-smoothing {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

@mixin inline-list($font-size: $font-size-base) {
	font-size: 0;

	> li {
		display: inline-block;
		font-size: $font-size;
		vertical-align: top;
	}
}

@mixin alt {
	color: transparent;
	font-size: 0;
	line-height: 0;
	text-shadow: none;
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
		height: 0;
		overflow: hidden;
	}

	&:after {
		clear: both;
	}
}

@mixin breakpoint($size) {
	@media (#{$size}) {
		@content;
	}
}
