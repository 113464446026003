// Skip link
.skip-to {
	background: color(base);
	color: #fff;
	display: block;
	left: -100%;
	padding: 10px;
	position: absolute;
	top: 0;
	z-index: 10;

	&:focus {
		left: 0;
	}
}

// Page Wrapper
.page-wrapper {
	margin: 0 auto;
	max-width: 1200px;
}

// Header
#header {
	@include clearfix;
	background: no-repeat 50% 50%;
	background-size: cover;
	height: 200px;
	position: relative;
	padding: 0 30px;
}

.header__logo {
	background: color(highlight);
	color: #fff;
	display: block;
	float: left;
	font-size: 20px;
	font-weight: bold;
	padding: 20px;

	&:hover {
		text-decoration: none;
	}
}

.header__nav {
	float: right;
	margin-top: 15px;

	ul {
		@include inline-list;
	}

	a {
		border-radius: 3px;
		color: #fff;
		display: block;
		padding: 10px;

		&:hover {
			background: #fff;
			color: color(highlight);
			text-decoration: none;
		}
	}
}

.header__intro {
	bottom: 30px;
	color: #fff;
	left: 30px;
	position: absolute;

	h1,
	h2 {
		font-size: 32px;
	}

	a {
		color: inherit;
	}
}

// Content
#content {
	border-top: 10px solid color(base);
	outline: none;
	padding: 30px;
}

// Footer
#footer {
	border-top: 5px solid color(base);
	color: lighten(color(base), 25%);
	font-size: 12px;
	padding: 30px;
}
