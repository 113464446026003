@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, button,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #333;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  width: 100%; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

img,
iframe {
  display: block;
  height: auto;
  max-width: 100%; }

a {
  color: #00a1e0;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.container {
  overflow: hidden; }

.contain:before, .contain:after {
  content: " ";
  display: table;
  height: 0;
  overflow: hidden; }

.contain:after {
  clear: both; }

.screen-reader {
  clip: rect(0 0 0 0);
  display: block !important;
  position: absolute;
  width: auto !important; }

.alt {
  color: transparent;
  font-size: 0;
  line-height: 0;
  text-shadow: none; }

.hide,
.js .js-hide {
  display: none; }

.left {
  float: left; }

.right {
  float: right; }

.no-float {
  float: none; }

.block {
  display: block; }

.centered {
  margin: 0 auto;
  text-align: center; }

.highlight {
  color: #00a1e0; }

label,
button {
  cursor: pointer; }

input,
select,
textarea,
button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font: inherit;
  margin: 0; }

button {
  background: none;
  border: none;
  line-height: 1;
  overflow: visible;
  width: auto; }

.skip-to {
  background: #333;
  color: #fff;
  display: block;
  left: -100%;
  padding: 10px;
  position: absolute;
  top: 0;
  z-index: 10; }
  .skip-to:focus {
    left: 0; }

.page-wrapper {
  margin: 0 auto;
  max-width: 1200px; }

#header {
  background: no-repeat 50% 50%;
  background-size: cover;
  height: 200px;
  position: relative;
  padding: 0 30px; }
  #header:before, #header:after {
    content: " ";
    display: table;
    height: 0;
    overflow: hidden; }
  #header:after {
    clear: both; }

.header__logo {
  background: #00a1e0;
  color: #fff;
  display: block;
  float: left;
  font-size: 20px;
  font-weight: bold;
  padding: 20px; }
  .header__logo:hover {
    text-decoration: none; }

.header__nav {
  float: right;
  margin-top: 15px; }
  .header__nav ul {
    font-size: 0; }
    .header__nav ul > li {
      display: inline-block;
      font-size: 16px;
      vertical-align: top; }
  .header__nav a {
    border-radius: 3px;
    color: #fff;
    display: block;
    padding: 10px; }
    .header__nav a:hover {
      background: #fff;
      color: #00a1e0;
      text-decoration: none; }

.header__intro {
  bottom: 30px;
  color: #fff;
  left: 30px;
  position: absolute; }
  .header__intro h1,
  .header__intro h2 {
    font-size: 32px; }
  .header__intro a {
    color: inherit; }

#content {
  border-top: 10px solid #333;
  outline: none;
  padding: 30px; }

#footer {
  border-top: 5px solid #333;
  color: #737373;
  font-size: 12px;
  padding: 30px; }

.articles__list > li + li {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  margin-top: 20px;
  padding-top: 20px; }

.articles__list-hdr {
  font-size: 18px;
  margin-bottom: 5px; }
  .articles__list-hdr a {
    display: block; }

.articles__list-summary {
  font-size: 14px;
  margin-bottom: 10px; }

.button {
  background: #00a1e0;
  border: 1px solid #00a1e0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  padding: 5px 10px; }
  .button:hover {
    background: #fff;
    color: #00a1e0;
    text-decoration: none; }

.button--large {
  font-size: 20px;
  padding: 10px 20px; }

.content {
  display: flex; }

.content__primary {
  padding-right: 30px;
  width: 66.66667%; }

.content__primary-hdr {
  font-size: 40px;
  margin-bottom: 20px; }

.content__secondary {
  background: #efefef;
  padding: 20px;
  width: 33.33333%; }

.content__secondary-hdr {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px; }

.fine-print {
  color: gray;
  font-size: 12px; }

.loans__list > li + li {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  margin-top: 30px;
  padding-top: 30px; }

.loans__list-container {
  align-items: center;
  display: flex; }

.loans__list-img {
  flex-shrink: 0; }

.loans__list-content {
  margin: 0 30px; }

.loans__list-hdr {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px; }

.loans__list-summary {
  font-size: 14px;
  margin-bottom: 15px; }

.loans__list-features {
  font-size: 14px;
  margin-bottom: 15px; }
  .loans__list-features > li {
    padding-left: 15px;
    position: relative; }
    .loans__list-features > li:before {
      color: #00a1e0;
      content: '✓';
      left: 0;
      position: absolute;
      top: 0; }

.loans__list-footnote {
  color: #666666;
  font-size: 12px;
  margin-bottom: 15px; }

.loans__list-cta {
  flex-shrink: 0; }
  .loans__list-cta .score {
    margin: 0; }
  .loans__list-cta a {
    display: block; }
  .loans__list-cta .button {
    margin: 20px 0 10px; }

.loans__list-read-review {
  font-size: 12px;
  text-align: center; }

.pagination {
  font-size: 0;
  margin-top: 40px;
  text-align: center; }
  .pagination > li {
    display: inline-block;
    font-size: 16px;
    vertical-align: top; }
  .pagination > li {
    margin: 0 10px; }

.reviews__list > li {
  align-items: center;
  display: flex; }
  .reviews__list > li + li {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    margin-top: 20px;
    padding-top: 20px; }

.reviews__list-logo {
  flex-shrink: 0; }

.reviews__list-content {
  margin: 0 30px; }

.reviews__list-hdr {
  font-size: 18px;
  margin-bottom: 5px; }
  .reviews__list-hdr a {
    display: block; }

.reviews__list-summary {
  font-size: 14px;
  margin-bottom: 10px; }

.reviews__list-cta {
  flex-shrink: 0; }

.score {
  line-height: 1;
  margin: 30px 0;
  text-align: center; }
  .score b {
    color: #00a1e0;
    display: block;
    font-size: 50px;
    font-weight: bold; }

.text blockquote,
.text ol,
.text ul,
.text p {
  margin: 20px 0; }

.text h1 {
  font-size: 30px;
  margin-top: 35px; }

.text h2 {
  font-size: 20px;
  margin-top: 35px;
  text-transform: uppercase; }

.text h3 {
  font-weight: bold;
  margin-top: 35px; }

.text ul {
  list-style: disc;
  margin-left: 20px; }

.text ol {
  list-style: decimal;
  margin-left: 20px; }

.text blockquote {
  color: #666666;
  font-size: 18px;
  margin-left: 20px;
  font-style: italic;
  margin: 30px auto;
  max-width: 700px;
  padding: 10px 20px; }

.text > :first-child {
  margin-top: 0; }

.text > :last-child {
  margin-bottom: 0; }
