$colors: (
	base: #333,
	highlight: #00a1e0
);

$fonts: (
	base: #{'Open Sans', Helvetica, sans-serif}
);

$breakpoints: (
	xsmall-down: 'max-width: 479px',
	xsmall-up: 'min-width: 480px',
	small-down: 'max-width: 639px',
	small-up: 'min-width: 640px',
	medium-down: 'max-width: 719px',
	medium-up: 'min-width: 720px',
	large-down: 'max-width: 999px',
	large-up: 'min-width: 1000px'
);

// Other
$font-size-base: 16px;
