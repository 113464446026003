label,
button {
	cursor: pointer;
}

input,
select,
textarea,
button {
	@include font-smoothing;
	font: inherit;
	margin: 0;
}

button {
	background: none;
	border: none;
	line-height: 1;
	overflow: visible;
	width: auto;
}
