.loans__list {
	> li {
		+ li {
			border-top: 1px solid rgba(color(base), 0.1);
			margin-top: 30px;
			padding-top: 30px;
		}
	}
}

.loans__list-container {
	align-items: center;
	display: flex;
}

.loans__list-img {
	flex-shrink: 0;
}

.loans__list-content {
	margin: 0 30px;
}

.loans__list-hdr {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 5px;
}

.loans__list-summary {
	font-size: 14px;
	margin-bottom: 15px;
}

.loans__list-features {
	font-size: 14px;
	margin-bottom: 15px;

	> li {
		padding-left: 15px;
		position: relative;

		&:before {
			color: color(highlight);
			content: '✓';
			left: 0;
			position: absolute;
			top: 0;
		}
	}
}

.loans__list-footnote {
	color: lighten(color(base), 20%);
	font-size: 12px;
	margin-bottom: 15px;
}

.loans__list-cta {
	flex-shrink: 0;

	.score {
		margin: 0;
	}

	a {
		display: block;
	}

	.button {
		margin: 20px 0 10px;
	}
}

.loans__list-read-review {
	font-size: 12px;
	text-align: center;
}
