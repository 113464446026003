.content {
	display: flex;
}

.content__primary {
	padding-right: 30px;
	width: percentage(2/3);
}

.content__primary-hdr {
	font-size: 40px;
	margin-bottom: 20px;
}

.content__secondary {
	background: #efefef;
	padding: 20px;
	width: percentage(1/3);
}

.content__secondary-hdr {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
}
