.button {
	background: color(highlight);
	border: 1px solid color(highlight);
	border-radius: 3px;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	padding: 5px 10px;

	&:hover {
		background: #fff;
		color: color(highlight);
		text-decoration: none;
	}
}

.button--large {
	font-size: 20px;
	padding: 10px 20px;
}
