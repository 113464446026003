.score {
	line-height: 1;
	margin: 30px 0;
	text-align: center;

	b {
		color: color(highlight);
		display: block;
		font-size: 50px;
		font-weight: bold;
	}
}
