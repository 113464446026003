html {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	@include font-smoothing;
	background: #fff;
	color: color(base);
	font-family: font(base);
	font-size: $font-size-base;
	line-height: (24/strip-units($font-size-base));
	height: 100%;
	width: 100%;
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

img,
iframe {
	display: block;
	height: auto;
	max-width: 100%;
}

a {
	color: color(highlight);
	text-decoration: none;

  &:hover {
  	text-decoration: underline;
  }
}
