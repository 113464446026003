.text {
	blockquote,
	ol,
	ul,
	p {
		margin: 20px 0;
	}

	h1 {
		font-size: 30px;
		margin-top: 35px;
	}

	h2 {
		font-size: 20px;
		margin-top: 35px;
		text-transform: uppercase;
	}

	h3 {
		font-weight: bold;
		margin-top: 35px;
	}

	ul {
		list-style: disc;
		margin-left: 20px;
	}

	ol {
		list-style: decimal;
		margin-left: 20px;
	}

	blockquote {
		color: lighten(color(base), 20%);
		font-size: 18px;
		margin-left: 20px;
		font-style: italic;
		margin: 30px auto;
		max-width: 700px;
		padding: 10px 20px;
	}

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}
