.reviews__list {
	> li {
		align-items: center;
		display: flex;

		+ li {
			border-top: 1px solid rgba(color(base), 0.1);
			margin-top: 20px;
			padding-top: 20px;
		}
	}
}

.reviews__list-logo {
	flex-shrink: 0;
}

.reviews__list-content {
	margin: 0 30px;
}

.reviews__list-hdr {
	font-size: 18px;
	margin-bottom: 5px;

	a {
		display: block;
	}
}

.reviews__list-summary {
	font-size: 14px;
	margin-bottom: 10px;
}

.reviews__list-cta {
	flex-shrink: 0;
}
